
import React from "react"

import Layout from "../components/Layout"
import Landing from "../components/Landing"

const HomePage = () => (
  <Layout seoProps={{title: "Find Natural Remedies for Illnesses with the herbs you love"}}>
    <Landing />
  </Layout>
)

export default HomePage
